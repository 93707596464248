import React from 'react'
import styled from 'styled-components'
import { CookieNotice } from 'gatsby-cookie-notice'
import Background from '../components/StreamerTemplate/Background'
import { useTranslation } from 'react-i18next'
import Footer from '../components/Footer/Footer'

const Wrapper = styled.section`
  width: 100%;
  min-height: 93vh;
  height: fit-content;
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 600px;
  background: white;
  padding: 50px;
`

function Imprint() {
  const { t } = useTranslation('')

  return (
    <Background>
      <CookieNotice
        personalizeButtonClasses={'personalize-button'}
        personalizeButtonText={'Individuelle Datenschutzeinstellungen'}
        backgroundWrapperClasses={'cookie-notice-wrapper bg-cookie'}
        declineButton={false}
        acceptButtonText={'Alle akzeptieren'}
        cookies={[
          {
            name: 'necessary',
            editable: false,
            default: true,
            title: 'Essential',
            text: 'Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.',
          },
          {
            name: 'gatsby-gdpr-google-analytics',
            editable: true,
            default: true,
            title: 'Google Analytics',
            text: 'Google Analytics is a statistical tool of Google allowing to measure the audience of the website.',
          },
        ]}
      >
        <h4>Datenschutzeinstellungen</h4>
        <p>
          Wir nutzen Cookies auf unserer Website. <br />
          Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Wir verwenden Cookies
          und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre
          Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen
          und Inhalte oder Anzeigen- und Inhaltsmessung.
          <p>Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung.</p>
          <p>Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder anpassen.</p>
          <p>
            Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre
            Erziehungsberechtigten um Erlaubnis bitten.
          </p>
        </p>
      </CookieNotice>
      <Wrapper>
        <ContentWrapper dangerouslySetInnerHTML={{ __html: t('imprint.content') }} />
      </Wrapper>
      <Footer />
    </Background>
  )
}

export default Imprint
